.c-downloadApp {
    position: fixed;
    top: 190px;
    left: -271px;
    overflow: hidden;
    display: flex;
    background-color: #000000;
    transition: left .3s ease;
    z-index: 9999;

    &-logos {
        display: grid;
        grid-template-columns: repeat(2, 105px);
        grid-gap: 20px;
        align-items: center;
        padding: 0 20px;

        svg {
            border: 1px solid rgb(100, 100, 100);
        }
    }

    &-tile {
        border-left: 1px solid rgb(100, 100, 100);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 5px;

        &__icon {
            margin-bottom: 7px;
        }

        p {
            color: #ffffff;
            font-size: 8px;
            font-family: 'Gotham-Medium', sans-serif;
            text-transform: uppercase;
            line-height: 10px;
        }
    }
}

.js-background {
    width: 100vw;
    height: 100vh;
    z-index: 9998;
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
}

.open {
    left: 0 !important;
}

.show {
    display: block !important;
}

@media (min-width: 768px) {
    .c-downloadApp {

        &:hover {
            left: 0;
        }
    }
}

@media only screen and (max-width: 400px) {
    .c-downloadApp {

        left: -241px;

        &-logos {
            grid-gap: 10px;
            padding: 0 10px;
        }
    }
}