.c-usps {
    $c: &;

    @apply .text-0;

    &__list {
        @apply .flex .flex-wrap;
    }

    &__item {
        @apply .w-full .mx-10 .relative;

        @screen lg {
            @apply .w-1/3 .mx-0;
        }

        @screen xl {
            padding-top: 380px;
            padding-bottom: 296px;
        }

        &:last-child {
            #{$c} {
                &__item-inner {
                    @apply .border-b-0 .pb-0 .mb-0;

                    @screen lg {
                        @apply .border-r-0;
                    }
                }
            }
        }

        &:hover,
        &:active {
            #{$c} {
                &__icon,
                &__bg-image {
                    @screen xl {
                        @apply .opacity-100;
                    }
                }

                &__icon {
                    @screen xl {
                        @apply .pb-16;
                    }
                }
            }

            .c-usp {
                &.fadeIn {
                    @screen xl {
                        @apply .pt-0;
                    }
                }

                &__body {
                    @screen xl {
                        max-height: 5000px;
                    }
                }
            }
        }
    }

    &__icon {
        left: 50%;

        transform: translateX(-50%);

        @apply .absolute .bottom-0 .opacity-0 .pb-0 .transition-all;
    }

    &__bg-image {
        filter: saturate(0);

        @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .opacity-0 .transition-all .bg-no-repeat .bg-cover .bg-center;

        &:after {
            content: '';

            @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .bg-black-alpha-70;
        }
    }

    &__item-inner {
        @apply .border-white-alpha-20 .border-b .pb-12 .mb-12 .z-10 .relative;

        @screen lg {
            min-height: 335px;

            @apply .border-r .border-b-0 .pb-0 .mb-0 .px-10;
        }

        @screen xl {
            @apply .px-24;
        }

        @screen xxl {
            @apply .px-40;
        }
    }
}
