.c-mobile-nav-handle {
    $c: &;

    @apply .relative .z-50;

    .hamburger-inner {
        &:before {
            left: 4px;

            transition: all .2s ease-in-out;
        }
    }

    &:hover,
    &:active {
        .hamburger-inner {
            &:before {
                left: 0;
            }
        }
    }

    &.is-active {
        .hamburger-inner {
            &:before {
                left: 0;
            }
        }
    }
}
