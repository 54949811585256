.c-checkbox {
    $c: &;

    @apply .flex .cursor-pointer .text-0 .leading-none;

    &__input {
        @apply .relative .w-3.5 .h-3.5 .border .border-secondary-lighter .flex-shrink-0;

        &:after {
            position: absolute;
            top: 2px;
            right: 2px;
            bottom: 2px;
            left: 2px;

            content: '';

            opacity: 0;

            @apply .bg-primary .transition-all;
        }
    }

    &__label {
        @apply .text-secondary-light .font-primary-light .flex-grow .text-sm .ml-5 .transition-all .leading-snug;

        @screen md {
            @apply .leading-none;
        }
    }
}
