.c-text {
    $c: &;

    @apply .text-sm;

    p {
        @apply .mb-8 .mt-0;

        &:last-child {
            @apply .mb-0;
        }
    }

    strong,
    b {
        @apply .font-primary-medium .font-normal;
    }

    a {
        @apply .text-primary .border-b .border-transparent .font-primary-medium .leading-snug;

        &:hover,
        &:active {
            @apply .border-primary;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        @apply .text-sm .font-primary-medium .leading-normal;
    }

    ul {
        @apply .mb-8;

        li {
            @apply .relative .pl-3;

            &:before {
                top: 4px;

                content: '•';

                @apply .absolute .left-0 .font-primary-light .text-sm .leading-none;
            }
        }

        &:last-child {
            @apply .mb-0;
        }
    }
}
