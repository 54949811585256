.c-heading {
    $c: &;

    &__inner {
        @apply .inline-block .relative;
    }

    &__title {
    }

    &__sub-title {
        letter-spacing: 1px;
        word-spacing: 3px;
        font-family: Gotham-Light, sans-serif;
        font-weight: bold;
        font-size: 20px;

        @apply  .text-primary .leading-none;

        @screen md {
            font-size: 20px
        }

        @screen xl {
            font-size: 20px;
        }
    }

    &.is-inverted {
        #{$c} {
            &__title {
                @apply .text-white;
            }
        }
    }
}
