@tailwind base;

@tailwind components;

/* purgecss start ignore */
@import "01-generics/_base.scss";
@import "01-generics/_fonts.scss";
@import "02-vendors/_aos.scss";
@import "02-vendors/_hamburgers.scss";
@import "02-vendors/_magnific-popup.scss";
@import "02-vendors/_photoswipe.scss";
@import "03-elements/_body.scss";
@import "03-elements/_button.scss";
@import "03-elements/_header.scss";
@import "03-elements/_heading.scss";
@import "03-elements/_input.scss";
@import "03-elements/_link.scss";
@import "03-elements/_logo.scss";
@import "04-components/_block.scss";
@import "04-components/_blog.scss";
@import "04-components/_checkbox.scss";
@import "04-components/_collapsable-item.scss";
@import "04-components/_collapsable-list.scss";
@import "04-components/_container.scss";
@import "04-components/_content-rows.scss";
@import "04-components/_content.scss";
@import "04-components/_cookie-consent.scss";
@import "04-components/_downloadApp.scss";
@import "04-components/_driver-type.scss";
@import "04-components/_figure.scss";
@import "04-components/_footer.scss";
@import "04-components/_form-field.scss";
@import "04-components/_form.scss";
@import "04-components/_gallery.scss";
@import "04-components/_heading.scss";
@import "04-components/_hero.scss";
@import "04-components/_icon.scss";
@import "04-components/_link.scss";
@import "04-components/_mobile-nav-handle.scss";
@import "04-components/_mobile-nav.scss";
@import "04-components/_nav.scss";
@import "04-components/_popup.scss";
@import "04-components/_sticky-nav.scss";
@import "04-components/_text.scss";
@import "04-components/_usp.scss";
@import "04-components/_usps.scss";
/* purgecss end ignore */

@tailwind utilities;