.c-usp {
    $c: &;

    @apply .h-full .transition-all;

    @screen xl {
        @apply .max-w-xs;
    }

    &__number {
        @apply .text-xxs .font-primary-medium .inline-block .text-primary .leading-none .mb-12;
    }

    &__title {
        @apply .leading-none .text-white;
    }

    &__body {
        @screen xl {
            transform: translateY(40px);
        }
    }

    &__text {
        @apply .text-white .font-primary-light .mt-10;

        @screen xl {
            @apply .mt-0;
        }
    }

    &__link {
        @apply .mt-10;
    }

    &.fadeIn {
        #{$c} {
            &__body {
                @screen xl {
                    max-height: 0;

                    @apply .overflow-hidden;
                }
            }
        }
    }
}
