.c-hero {
    height: 480px;

    $c: &;

    @apply .bg-no-repeat .bg-cover .bg-center;

    @screen md {
        height: 600px;
    }

    &__inner {
        @apply .flex .items-center .h-full;
    }

    &__content {
        @apply .max-w-xxs;
    }

    &__title {
        @apply .text-white .text-4.5xl .font-primary-light .leading-none;

        @screen md {
            @apply .text-5xl;
        }
    }
}
