// -----------------------------------------------------------------------------
// elements/input
// -----------------------------------------------------------------------------

input,
textarea {
    @apply .p-0;

    @apply .rounded-none;

    &:focus {
        @apply .outline-none;
    }

    &::placeholder {
        @apply .text-secondary-light .opacity-100;
    }
}

textarea {
    -webkit-appearance: none;

    @apply .resize-none;
}

select {
    -webkit-border-radius: 0;

    -webkit-appearance: none;

    &:focus {
        @apply .outline-none;
    }
}

input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
