.c-nav {
    $c: &;

    @apply .text-0;

    &__list {
    }

    &__item {
    }

    &__link {
    }

    &__label {
        @apply .inline-block .transition-all;
    }

    &--primary {
        @apply .text-center;

        #{$c} {
            &__list {
                @apply .-mb-10;

                @screen md {
                    @apply .-mb-12;
                }

                @screen xl {
                    @apply .-mb-16;
                }
            }

            &__item {
                @apply .pb-10;

                @screen md {
                    @apply .pb-12;
                }

                @screen xl {
                    @apply .pb-16;
                }

                &.is-active {
                    #{$c} {
                        &__label {
                            @apply .text-primary;
                        }
                    }
                }
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label {
                            @apply .text-primary;
                        }
                    }
                }
            }

            &__label {
                @apply .text-xl .text-white .lowercase .font-primary-extra-light .leading-none;

                @screen md {
                    @apply .text-3xl;
                }

                @screen xl {
                    @apply .text-4.5xl;
                }
            }
        }
    }

    &--secondary {
        @apply .w-full;

        #{$c} {
            &__list {
                @apply .flex .flex-wrap .w-full .-mb-4;

                @screen md {
                    @apply .mb-0;
                }
            }

            &__item {
                @apply .w-full .relative .px-12 .text-center .pb-4;

                @screen md {
                    @apply .w-1/2 .px-20 .pb-0 .text-left;
                }

                &:first-child {
                    @screen md {
                        @apply .text-right;
                    }
                }

                &:after {
                    @screen md {
                        width: 21px;
                        height: 31px;

                        content: '';
                        transform: translate(50%, 0);

                        background-image: url('/images/privatedrivers-icon-white.svg');

                        @apply .absolute .right-0 .top-0 .inline-block .bg-no-repeat .bg-center .bg-cover;
                    }
                }

                &:last-child {
                    @screen md {
                        @apply .pr-0;
                    }

                    &:after {
                        @apply .hidden;
                    }
                }
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label {
                            @apply .text-primary;
                        }
                    }
                }
            }

            &__label {
                @apply .text-base .text-white .font-primary-light .leading-none;
            }
        }
    }

    &--footer {
        #{$c} {
            &__list {
                @apply .-ml-12 .-mb-4;
            }

            &__item {
                @apply .text-center .pl-12 .pb-4;

                @screen md {
                    @apply .inline-block .text-left;
                }
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label {
                            @apply .text-primary;
                        }
                    }
                }
            }

            &__label {
                @apply .text-xbase .text-white .font-primary-light .leading-none .lowercase;
            }
        }
    }

    &--legal {
        #{$c} {
            &__list {
                @apply .-ml-6 .-mb-2;
            }

            &__item {
                @apply .inline-block .pl-6 .pb-2;
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label {
                            @apply .text-white;
                        }
                    }
                }
            }

            &__label {
                @apply .text-white-alpha-40 .font-primary-light .text-xsm .lowercase .leading-none;
            }
        }
    }

    &--language {
        #{$c} {
            &__list {
                @apply .flex .justify-center .-ml-4 .-mb-4;

                @screen md {
                    @apply .block;
                }
            }

            &__item {
                @apply .pb-4 .pl-4;

                &.is-active {
                    #{$c} {
                        &__label {
                            @apply .font-primary-medium .border-white;
                        }
                    }
                }
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label {
                            @apply .border-white;
                        }
                    }
                }
            }

            &__label {
                @apply .text-sm .text-white .uppercase .font-primary-light .leading-none .border-b border-transparent;
            }
        }
    }

    &--social {
        #{$c} {
            &__list {
                @apply .flex .-ml-4;

                @screen md {
                    @apply .block;
                }
            }

            &__item {
                @apply .pl-4;
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__icon {
                            .c-icon {
                                @apply .text-primary;
                            }
                        }
                    }
                }
            }

            &__icon {
                @apply .text-white;
            }
        }
    }
}
