.c-blog {
    $c: &;

    @apply .text-0 .w-full .relative;

    &__figure {
        min-height: 280px;

        filter: saturate(0);

        @apply .bg-center .bg-no-repeat .bg-cover .transition-all;

        @screen xl {
            min-height: 408px;
        }
    }

    &__image {
    }

    &__content {
        @apply .mt-10;
    }

    &__category {
        @apply .inline-block .text-xxs .uppercase .text-secondary-light .font-primary-medium .leading-none .mb-8;
    }

    &__title {
    }

    &__preface {
        @apply .mt-5;
    }

    &__read-more {
        top: 100%;

        transform: translateX(-25%);

        @apply .absolute .mt-10 .opacity-0 .transition-all;
    }

    &:hover,
    &:active {
        @screen xl {
            transform: translateY(-18px);
        }

        #{$c} {
            &__figure {
                filter: saturate(100%);
            }

            &__read-more {
                @screen xl {
                    transform: translateX(0);

                    @apply .opacity-100;
                }
            }
        }
    }

    &.has-no-image {
        #{$c} {
            &__figure {
                @apply .border .border-secondary-darker .w-full .h-full .flex .items-center .justify-center;
            }
        }

        &:hover,
        &:active {
            #{$c} {
                &__figure {
                    @apply .border-primary;

                    .c-icon {
                        @apply .text-primary;
                    }
                }
            }
        }
    }
}
