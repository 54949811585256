// -----------------------------------------------------------------------------
// elements/heading
// -----------------------------------------------------------------------------

h1,
h2,
h3,
h4,
h5 {
    @apply .font-primary-light .leading-tight .text-black;
}

// 48px
h1,
.h1 {
    @apply .text-4xl;

    @screen xl {
        @apply .text-5xl;
    }
}

// 40px
h2,
.h2 {
    @apply .text-3xl;

    @screen xl {
        @apply .text-4.5xl;
    }
}

// 24px
h3,
.h3 {
    @apply .text-2xl;
}

h4,
.h4 {
    @apply .text-xl;
}

h5,
.h5 {
    @apply .text-base;
}
