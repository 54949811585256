.c-mobile-nav {
    position: fixed;
    z-index: -1;
    top: 0;

    width: 100%;
    height: 100%;

    opacity: 0;

    @apply .bg-secondary-darker .transition-all .overflow-y-scroll;

    &.is-active {
        opacity: 1;

        @apply .transition-all .z-20;
    }
}
