.c-block {
    $c: &;

    &__figure {
        @apply .w-full;
    }

    &__image {
        filter: saturate(0);

        @apply .w-full .transition-all;
    }

    &:hover,
    &:active {
        #{$c} {
            &__image {
                filter: saturate(100%);
            }
        }
    }
}
