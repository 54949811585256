.c-collapsable-item {
    $c: &;

    @apply .text-0 .border-b .border-secondary-lighter;

    &__heading {
        @apply .flex .items-center .cursor-pointer .pb-2;
    }

    &__title {
        @apply .text-sm .flex-grow .text-secondary-light .font-primary-light .transition-all;
    }

    &__toggle {
        @apply .flex-shrink-0 .transition-all .text-secondary-lighter;
    }

    &__content {
        max-height: 0;

        transition: max-height .5s cubic-bezier(0, 1, 0, 1);

        @apply .overflow-hidden;
    }

    &__content-inner {
        @apply .pt-4 .pb-6;
    }

    &:hover,
    &:active {
        #{$c} {
            &__title {
                @apply .text-black;
            }

            &__toggle {
                .c-icon {
                    @apply .text-black;
                }
            }
        }
    }

    &.is-open {
        @apply .border-black;

        #{$c} {
            &__heading {
            }

            &__title {
                @apply .text-black .font-primary-medium;
            }

            &__toggle {
                transform: rotate(180deg);

                .c-icon {
                    @apply .text-black;
                }
            }

            &__content {
                max-height: 1000px;

                transition: max-height 1s ease-in-out;
            }
        }
    }
}
