.c-content {
    $c: &;

    &__section {
        @apply .relative;

        &--intro {
            &:after {
                @screen lg {
                    height: 160px;

                    content: '';

                    @apply .w-full .absolute .bottom-0 .left-0 .right-0 .bg-white;
                }
            }
        }
    }

    &__section-inner {
        @apply .relative .z-20;
    }
}
