.c-gallery {
    $c: &;

    @apply .relative .text-0;

    &__list {
    }

    &__item {
    }

    &__link {
        @apply .w-full;

        &:hover,
        &:active {
        }
    }

    &__image {
        @apply .object-cover .w-full .h-88;

        @screen lg {
            @apply .h-full;
        }
    }

    &__indicator {
        @apply .absolute .bottom-0 .right-0 .w-12 .h-12 .flex .items-center .justify-center .text-white .font-primary-light .text-base .border-white .border-l .border-t;

        &:hover,
        &:active {
            @apply .bg-white .text-secondary-darker;
        }
    }
}
