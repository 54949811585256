.c-container {
    $c: &;

    @apply .relative .w-full .px-10 .mx-auto;

    @screen xl {
        @apply .max-w-7xl; // 1200px
    }

    &--large {
        @screen xl {
            @apply .max-w-8xl; // 1600px
        }
    }
}
