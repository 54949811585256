.c-footer {
    $c: &;

    @apply .bg-secondary-darker;

    &__top {
        @apply .pt-16 .pb-12 .flex .flex-col .items-center .justify-center;
    }

    &__bottom {
        @apply .border-t .border-white-alpha-20 .py-8;

        .c-nav {
            &--social {
                .c-nav {
                    &__link {
                        &:hover,
                        &:active {
                            .c-nav__icon {
                                .c-icon {
                                    @apply .text-white;
                                }
                            }
                        }
                    }

                    &__icon {
                        @apply .text-white-alpha-40;
                    }
                }
            }
        }
    }
}
