.c-icon {
    @apply .fill-current .transition-all;

    &--arrow {
        width: 11px;
        height: 6px;
    }

    &--arrow-long {
        width: 40px;
        height: 20px;
    }

    &--privatedrivers {
        width: 32px;
        height: 48px;

        &.is-small {
            width: 17px;
            height: 25px;
        }
    }

    &--facebook {
        width: 12px;
        height: 24px;

        &.is-small {
            width: 10px;
            height: 20px;
        }
    }

    &--instagram {
        width: 24px;
        height: 24px;

        &.is-small {
            width: 20px;
            height: 20px;
        }
    }

    &--envelope {
        width: 24px;
        height: 18px;
    }
}
