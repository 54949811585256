.c-link {
    $c: &;

    @apply .relative;

    &__label {
        @apply .inline-block .leading-none .font-primary-medium .uppercase .text-xxs .pb-1 .transition-all;
    }

    &__icon {
        @apply .inline-block .transition-all;
    }

    &:after,
    &:before {
        top: 100%;

        content: '';

        @apply .absolute .left-0 .right-0 .h-px;
    }

    &:after {
        @apply .w-full .z-10;
    }

    &:before {
        @apply .w-0 .z-20 .transition-all;
    }

    &:hover,
    &:active {
        &:before {
            @apply .w-full;
        }
    }

    &--primary {
        #{$c} {
            &__label {
                @apply .text-secondary-light;
            }

            &__icon {
            }
        }

        &:after {
            @apply .bg-secondary-lighter;
        }

        &:before {
            @apply .bg-primary;
        }

        &:hover,
        &:active {
            #{$c} {
                &__label {
                    @apply .text-primary;
                }
            }
        }
    }

    &--secondary {
        #{$c} {
            &__label {
                @apply .text-secondary-light;
            }

            &__icon {
            }
        }

        &:after {
            @apply .bg-secondary-lighter;
        }

        &:before {
            @apply .bg-secondary-darker;
        }

        &:hover,
        &:active {
            #{$c} {
                &__label {
                    @apply .text-secondary-darker;
                }
            }
        }
    }

    &--tertiary {
        #{$c} {
            &__label {
                @apply .text-white;
            }

            &__icon {
            }
        }

        &:after {
            @apply .bg-white-alpha-30;
        }

        &:before {
            @apply .bg-primary;
        }

        &:hover,
        &:active {
            #{$c} {
                &__label {
                    @apply .text-primary;
                }
            }
        }
    }

    &--quaternary {
        #{$c} {
            &__label {
                @apply .text-primary;
            }

            &__icon {
            }
        }

        &:after {
            @apply .bg-primary;
        }

        &:before {
            @apply .bg-secondary-darker;
        }

        &:hover,
        &:active {
            #{$c} {
                &__label {
                    @apply .text-secondary-darker;
                }
            }
        }

        &.is-inverted {
            &:hover,
            &:active {
                &:before {
                    @apply .bg-white;
                }

                #{$c} {
                    &__label {
                        @apply .text-white;
                    }
                }
            }
        }
    }

    &--quinary {
        #{$c} {
            &__label {
                @apply .text-secondary;
            }

            &__icon {
            }
        }

        &:after {
            @apply .bg-secondary;
        }

        &:before {
            @apply .bg-secondary-light;
        }

        &:hover,
        &:active {
            #{$c} {
                &__label {
                    @apply .text-secondary-light;
                }
            }
        }
    }

    &.is-bouncing {
        animation: arrow-bounce 2s infinite;
    }
}

@keyframes arrow-bounce {
    0% {
    }
    50% {
        transform: translateY(8px);
    }
    100% {
        transform: translateY(0);
    }
}
