.c-form {
    $c: &;

    @apply .text-0;

    &__fieldsets {
        @apply .-mb-16;

        @screen md {
            @apply .-mb-20;
        }
    }

    &__fieldset {
        @apply .pb-16;

        @screen md {
            @apply .pb-20;
        }
    }

    &__fieldset-title {
        @apply .mb-8 .text-primary .font-primary-medium .uppercase .text-xxs .tracking-1px .leading-none;
    }

    &__rows {
        @apply .-mb-6;
    }

    &__row {
        @apply .pb-6;
    }

    &__columns {
        @apply .flex .flex-wrap .-ml-6 .-mb-6;
    }

    &__column {
        @apply .w-full .pb-6 .pl-6;

        @screen md {
            @apply .flex-1;
        }
    }
}
