.c-content-rows {
    $c: &;

    @apply .text-0;

    &__list {
        @apply .-mb-16;

        @screen md {
            @apply .-mb-24;
        }

        @screen lg {
            @apply .-mb-32;
        }
    }

    &__item {
        @apply .pb-16;

        @screen md {
            @apply .pb-24;
        }

        @screen lg {
            @apply .pb-32;
        }
    }
}
