.c-sticky-nav {
    top: 50%;
    right: 0;

    transform: translateY(-50%);

    $c: &;

    @apply .fixed .z-20;

    &__list {
    }

    &__item {
    }

    &__link {
        transform: translateX(100%) translateX(-60px);

        @apply .bg-secondary-darker .inline-flex .items-center;

        &:hover,
        &:active {
            transform: translateX(0);
        }
    }

    &__icon {
        width: 60px;
        height: 60px;

        @apply .flex .items-center .justify-center .text-white;
    }

    &__label {
        @apply .text-white .font-primary-medium .uppercase .text-xs .leading-none .pr-6 .tracking-widest;
    }
}
