.c-form-field {
    $c: &;

    @apply .relative .h-full .text-0;

    &__label {
        transition: all .8s cubic-bezier(0, 1, 0, 1);

        @apply .absolute .text-sm .leading-none .text-secondary-light .z-10 .left-0 .bottom-0 .right-auto .pb-2 .h-full .whitespace-no-wrap .cursor-text;
    }

    &__input {
        @apply .text-sm .leading-none .relative .text-black .w-full .h-full .border-b .border-secondary-lighter .bg-white .transition-all .pb-2 .font-primary-light;

        &:focus {
            @apply .border-black;

            + #{$c} {
                &__label {
                    left: 100%;

                    transform: translate(-100%, 0);
                }
            }
        }

        &:not([value='']):not(:focus) {
            + #{$c} {
                &__label {
                    right: 0;
                    left: auto;
                }
            }
        }
    }

    &__errors {
        @apply .text-0;
    }

    &.has-errors {
        #{$c} {
            &__label {
                @apply .text-error;
            }

            &__input {
                @apply .border-error;

                &::placeholder {
                    @apply .text-error;
                }
            }
        }
    }

    &--textarea {
        #{$c} {
            &__label {
                @apply .hidden;
            }

            &__input {
                @apply .leading-snug;
            }
        }
    }

    &--checkbox {
        #{$c} {
            &__input[type=radio],
            &__input[type=checkbox] {
                @apply .w-0 .h-0 .opacity-0 .text-0 .leading-none .invisible .m-0 .p-0;

                &:checked {
                    + #{$c} {
                        &__label {
                            .c-checkbox {
                                &__input {
                                    @apply .border-secondary-lighter;

                                    &:after {
                                        opacity: 1;
                                    }
                                }

                                &__label {
                                    @apply .text-black;
                                }
                            }

                            #{$c} {
                                &__input[type=text] {
                                    @apply .border-black .text-black;
                                }
                            }
                        }
                    }
                }
            }

            &__label {
                @apply .relative .block .pb-0 .whitespace-normal;
            }
        }

        &.has-errors {
            .c-checkbox {
                &__input {
                    @apply .border-error;
                }

                &__label {
                    @apply .text-error;
                }
            }

            #{$c} {
                &__label {
                    @apply .text-error;
                }
            }
        }
    }
}
