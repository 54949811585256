.c-driver-type {
    $c: &;

    &__input {
        @apply .w-0 .h-0 .opacity-0 .text-0 .leading-none .invisible .m-0 .p-0;

        &:checked {
            + #{$c} {
                &__label {
                    #{$c} {
                        &__box {
                            @apply .border-primary;
                        }
                    }
                }
            }
        }
    }

    &__label {
    }

    &__box {
        @apply .bg-secondary-dark .px-6 .pt-12 .pb-8 .text-center .cursor-pointer .border .border-secondary-dark .transition-all;
    }
}
