.c-header {
    $c: &;

    @apply .border-b .border-secondary-lighter .text-0 .px-12;

    &__inner {
        height: 90px;

        @screen md {
            height: 120px;
        }

        @apply .relative .flex .items-center .justify-center .flex-grow;
    }

    &__contact-links {
        @apply .hidden;

        @screen lg {
            @apply .block .absolute .left-0;
        }
    }

    &__logo {
        @apply .flex-grow .pr-6;

        @screen lg {
            @apply .flex-grow-0 .pr-0;
        }
    }

    &__nav-handle {
        @apply .relative .right-0 .h-full .flex .items-center .flex-shrink-0 .z-20;

        @screen lg {
            @apply .fixed .ml-0 .mr-12 .h-auto;
        }
    }

    &.is-inverted {
        @apply .bg-secondary-darker .border-white-alpha-20;

        .c-mobile-nav-handle {
            .hamburger-inner,
            .hamburger-inner:after,
            .hamburger-inner:before {
                @apply .bg-white;
            }
        }
    }
}
